import React from 'react';
import './App.css';
import CheckCode from './CheckCode';

const App = () => (
  <div className="App">
    <CheckCode />
  </div>
);


export default App;