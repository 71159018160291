import React, { Component } from 'react'
import { Input, Card, message, Spin } from 'antd';
import './App.css';

const axios = require('axios');

export default class CheckCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: '09',
            res_guid: '',
            copied: false,
            code: '',
            loading: false,
            queryUserExist: 0, // 0 not yet, 1 exist, 2 no exist
            data: [
                { key: '用户名', value: 'bautr' },
                { key: '手机', value: 'bautr' },
                { key: '余额', value: 'bautr' },
                { key: '卡号', value: 'bautr' },
                { key: '卡登记', value: 'bautr' },
            ],
        };
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.sendSmsCode = this.sendSmsCode.bind(this);
        this.getMobile = this.getMobile.bind(this);
    }

    componentDidMount() {
        this.getMobile();
        setTimeout(() => {
            console.log('ok', this.state.phone) 
            this.sendSmsCode();
        }, 100);
    }

    getMobile = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const mobile = params.get('mobile');
        const res_guid = params.get('res_guid');
        this.setState({ 
            phone: mobile,
            res_guid: res_guid,
        });
    }

    handleChangePhone(event) {
        console.log(event.target.value);
        this.setState({ phone: event.target.value });
    }

    sendCode() {
        return axios.post(`${process.env.REACT_APP_SERVER}/api/users/${this.state.phone}/sendcode`, {
            'key': this.state.phone,
            'guid': this.state.res_guid
        })
    }

    sendSmsCode() {
        if (this.state.phone.length !== 11 && this.state.phone.length !== 12) {
            console.log('not ok');
            message.error('手机号格式不正确');
            return;
        }

        this.setState({ loading: true })

        const result = this.sendCode();
        result.then((response) => {
            // handle success
            console.log('success');
            console.log(response);
            this.setState({ code: response.data.ucode })
            message.success('校验短信已发送');
        })
            .catch(function (error) {
                // handle error
                console.log('error');
                message.error('错误, 请检查网络');
                console.log(error);
            })
            .then(() => (
                // always executed
                this.setState({ loading: false }))
            );
    }

    renderCode = () => (
        this.state.code && <h2 className="code">Code: {this.state.code}</h2>
    )

    render() {
        return (
            <div>
                <Spin spinning={this.state.loading} />
                <Card className="Card" title="校验手机号">
                    <Input
                        className="Input"
                        placeholder="请输入手机号码"
                        value={this.state.phone}
                        onChange={this.handleChangePhone}
                        readOnly
                    />
                    {this.renderCode()}
                </Card>
            </div>
        )
    }
}
